import axios from "axios";
const api= axios.create(
    {
        // baseURL:"http://localhost:8080",
        baseURL:"https://server.belestech.com",
        
        headers: {
                "Content-Type": "application/vnd.api+json",
            },
        withCredentials:true
    }
)
export default api;