import React ,{useEffect, useRef}from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style/home.css'; // Custom CSS for additional styling
import Features from './Features';
import cactusProducts from './img/Cactus.jpg';
import missionImage1 from './img/mission1.jpg';
import missionImage2 from './img/cactus-cutting.png';
import missionImage3 from './img/mission3.jpg';

const Homepage = () => {
  const homeref= useRef()
  useEffect(()=>{
      homeref.current.scrollIntoView({behavior:'smooth'})
  },[])
  return (
    <>
      <section ref={homeref} className="homepage-hero "style={{ backgroundColor: '#e8f5e9' }}>
        <div className="container pt-md-5 ">
          <div className="row align-items-center pt-md-5">
            <div className="col-md-6">
              <h1 className="homepage-hero-title">Harnessing the Power of Cactus pear for Sustainable Growth</h1>
              <p className="homepage-hero-description">
                Explore innovative solutions in Cactus pear-based products, from food to fabrics, while promoting sustainable farming practices.
              </p>
              <a href="#mission"  className="btn homepage-get-started-button">Get Started</a>
            </div>
            <div className="col-md-6">
              <img src={cactusProducts} alt="Cactus pear Landscape" className="img-fluid homepage-hero-image" />
            </div>
          </div>
        </div>
      </section>

      <section className="py-5" id="mission">
        <div className="container1">
          <div className="row justify-content-center text-center mb-3 mb-md-5">
            <div className="col-lg-8 col-xxl-7">
              <span className="text-muted">Mission and Vision Highlights</span>
              <h2 className="display-5 fw-bold mb-3">Our Mission & Vision</h2>
              <p className="lead">
                At Beles Tech, we are committed to transforming Cactus pear farming into a sustainable economic driver, fostering innovation, and promoting environmental stewardship.
              </p>
            </div>
          </div>

          <div className="row text-center" >
            <div className="col-md-4">
              <img src={missionImage1} alt="Mission Highlight 1" className="img-fluid mission-highlight-image mb-3" />
              <h6>Innovative Farming Practices</h6>
              <p>We strive to implement cutting-edge techniques in Cactus pear farming to maximize yield and sustainability.</p>
            </div>
            <div className="col-md-4">
              <img src={missionImage2} alt="Mission Highlight 2" className="img-fluid mission-highlight-image mb-3" />
              <h6>Community Engagement</h6>
              <p>Our mission includes empowering local communities through education and resources to promote sustainable farming.</p>
            </div>
            <div className="col-md-4">
              <img src={missionImage3} alt="Mission Highlight 3" className="img-fluid mission-highlight-image mb-3" />
              <h6>Research and Development</h6>
              <p>We are dedicated to advancing research in Cactus pear products to enhance their viability in various industries.</p>
            </div>
          </div>

          <div className="text-center mt-4 p-5">
    <a href="/about" className="btn homepage-get-started-button mb-2">Learn More</a>
    <a href="/research" className="btn homepage-get-started-button mb-2 mx-1">Our Research</a>
    <a href="/contact" className="btn homepage-get-started-button mb-2 mx-1">Get Involved</a>
</div>
        </div>
      </section>

      <div className="homepage-hero-text text-center">
        <Features />
      </div>
    </>
  );
};

export default Homepage;
