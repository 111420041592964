import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import projectImage1 from './img/col1.jpg'; 
import projectImage2 from './img/col2.jpg';
import projectImage3 from './img/farm.jpg';
import projectImage4 from './img/ino.jpg';
import projectImage5 from './img/mission1.jpg';
import projectImage6 from './img/production.png';
import { getAllProjectImages, getAllProjects } from './api/api_functions';

const projects = [
  {
    image: projectImage1,
    title: "Sustainable Cactus-Based Fabrics",
    description: "This project explores the innovative use of cactus fibers in textile production. By utilizing natural materials, we aim to reduce reliance on synthetic fabrics, lower carbon emissions, and create biodegradable products. Our research focuses on developing a sustainable supply chain that benefits local farmers and promotes environmental stewardship.",
    alignLeft: true
  },
  {
    image: projectImage2,
    title: "Bioenergy Production",
    description: "In our bioenergy production project, we investigate sustainable methods for generating energy from organic materials. This includes the use of agricultural waste and biomass to produce biofuels and biogas, significantly reducing waste and providing renewable energy solutions. Our goal is to create a model that can be replicated in various agricultural contexts to promote energy independence.",
    alignLeft: false
  },
  {
    image: projectImage3,
    title: "Advanced Farming Techniques",
    description: "We are developing cutting-edge farming techniques that enhance crop yields while minimizing resource use. Our research includes precision agriculture, vertical farming, and the use of drones for monitoring crop health. By integrating technology with traditional practices, we aim to create a more efficient and sustainable agricultural system that can withstand climate challenges.",
    alignLeft: true
  },
  {
    image: projectImage4,
    title: "Innovative Agricultural Solutions",
    description: "This project focuses on the integration of new technologies in agriculture, including smart irrigation systems and soil health monitoring. We aim to improve efficiency and sustainability through data-driven practices that optimize resource use and increase productivity, benefiting both farmers and the environment.",
    alignLeft: false
  },
  {
    image: projectImage5,
    title: "Mission-Driven Agriculture",
    description: "Our mission-driven agriculture projects are centered on sustainable practices that align with community needs. We work directly with local farmers to implement eco-friendly techniques, enhance biodiversity, and improve food security. This initiative aims to foster a resilient agricultural community that prioritizes environmental health and social equity.",
    alignLeft: true
  },
  {
    image: projectImage6,
    title: "Future of Farming",
    description: "Join us in exploring the future of farming through innovative practices and technologies. This project examines trends such as agroecology, regenerative agriculture, and the role of AI in farming. Our objective is to prepare for a sustainable future in agriculture that balances productivity with ecological responsibility.",
    alignLeft: false
  }
];

const Project = () => {
  const [allprojects,setProjects]=useState(null);
  const [allimage,setImage]=useState([]);
  useEffect(()=>{
    getAllProjects().then((res)=>{
      console.log("projects",res.data);
      setProjects(res.data);
    });
    getAllProjectImages().then((res)=>{
      console.log("images",res.data)
        setImage(res.data);
    })
  },[])
  return (
    <div className="container1 my-5">
      <Row className="mt-4">
        <Col sm={12} pt={3}>
       
          <h1 className="h2 mb-4 text-center display-4 fw-bold" style={{ fontWeight: 600, color: '#4CAF50', fontSize: '2.5rem' }}>
            About Our <span className="text-success">Projects</span>
          </h1>
          <p className="text-secondary text-center" style={{ lineHeight: 2, fontSize: '1.2rem' }}>
            Explore our major research projects focused on sustainability and innovation in agriculture.
          </p>
        </Col>
        
      </Row>
      <div className='container-fluid p-3 p-lg-4'>
        <Row className="w-500">
          <Col md={12} className="pt-3">
          
            {allprojects&&allprojects.map((project, index) =>{ 
                const [image]=allimage.filter((image)=>image.projectId==project.projectId && image.isThumbnail==1)
                const image_url=image && image.file.split("/").pop();
                return(
              <Row className="mb-4 w-100" key={index} style={{ flexDirection: index%2==0 ? 'row' : 'row-reverse', width: '100%' }}>
                <Col md={4} className="text-center mx-auto d-flex align-items-center justify-content-center">
                  <img src={`https://server.belestech.com/src/uploads/${image_url}`} className="img-fluid rounded" alt={project.title} style={{ maxHeight: '500px', boxShadow: '0 0 15px #eee', width: '100%' }} />
                </Col>
                <Col md={7} className="d-flex mx-auto flex-column justify-content-center">
                  <h5 style={{ color: '#4CAF50', fontSize: '1.5rem' }}>{project.title}</h5>
                  <p className="text-secondary" style={{ lineHeight: 1.5, fontSize: '1rem' }}>{project.description}</p>
                </Col>
              </Row>
            )})}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Project;
