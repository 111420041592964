import React, { useState,useEffect } from 'react';
import upcomingEventImg from './img/Farmers day flyer design.jpg';
import pastEventImg from './img/story.jpg';
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment';
import './style/Event.css'; // Import your CSS file for styles
import { getAllEvents } from './api/api_functions';

const imageStyle = {
    borderRadius: '0.5rem',
    width: '100%',
    height: 'auto',
};

const textContainerStyle = {
    backgroundColor: 'white',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '2rem', // Adjusted padding for consistency
};

const headingStyle = {
    color: '#28a745',
    fontWeight: 'bold',
    opacity:"100%",
    borderRadius:"5px"
};

const buttonStyle = {
    backgroundColor: '#28a745',
    color: 'white',
    padding: '0.5rem 1.5rem',
    border: 'none',
    borderRadius: '0.25rem',
    cursor: 'pointer',
};

const buttonHoverStyle = {
    backgroundColor: '#218838',
};

const Event = () => {
    const [pastEvents,setPastEvents]=useState([]);
    const [upcomingEvents,setupcomingEvents]=useState([]);
    useEffect(()=>{
        getAllEvents().then((res)=>{
    
            if(res.data){
                const past=[];
                const  upcoming=[];
                res.data && res.data.map((event)=>{
                    if(moment().isAfter(moment(event.date)))
                        {
                            past.push(event);
                        }
                        else{
                            upcoming.push(event)
                        }
                })
                    setPastEvents(past);
                    setupcomingEvents(upcoming);
            }
        })
    },[])
    return (
        <div className="container1 mt-3 pt-5"> 
            <header className="text-center mb-2">
                <h1  >Cactus pear Farming & Sustainable Agriculture</h1>
                <p>Your hub for knowledge and networking</p>
            </header>

            <div className="row align-items-center">
               {upcomingEvents.length>0 && <div className="col-lg-12  mx-auto mb-4 mb-lg-0"> {/* Margin for mobile */}
                    <div className='h-100'
                        style={{
                            ...imageStyle,
                           
                            padding:"10px",
                            backgroundRepeat:"no-repeat",
                            backgroundImage:`URL('${upcomingEventImg}')`,
                            backgroundSize:"cover"
                            
                        }}
                        // src={upcomingEventImg}
                        // alt="Upcoming Event"
                >
                    <div className="row mx-auto  rounded"style={{
                       
                        opacity:"100%"
                    }}   
                    >
                        <h2 className='text-center bg-white display-4 p-2' style={headingStyle}>Upcoming Events</h2>
                         {
                            upcomingEvents && upcomingEvents.map((event,index)=>{
                                
                                const banner= event.banner.split("/").pop();
                                
                                return(
                                    <div key={index} style={{opacity:"100%"}} className='col-sm-12 rounded col-md-4 bg-white mx-auto p-3 mt-2 d-flex flex-column'>
                                        <img src={`https://server.belestech.com/src/uploads/${banner}`} className='img-fluid rounded' style={{maxHeight:"300px"}}  alt={event.title}/>
                                    <h3 className="mt-3 text-uppercase">{event.title}</h3>
                                    <p className="mt-2">
                                        {event.description}
                                    </p>
                                    <p className="mt-2">
                                      Location: {event.location}
                                    </p>
                                    <p className="mt-2 text-secondary small">
                                        Date: {moment(event.date).format('MMMM Do YYYY')}
                                    </p>
                                    <div className="mt-2">
                                        <button
                                            style={buttonStyle}
                                            onMouseOver={(e) => e.currentTarget.style.backgroundColor = buttonHoverStyle.backgroundColor}
                                            onMouseOut={(e) => e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor}
                                        >
                                            Learn More
                                        </button>
                                    </div>
                                    </div>
                                )
                            })
                        } 
                       
                    </div>
                </div>
                    
                </div>}
                {pastEvents.length>0 && <div className="container mt-4  mx-auto  mb-4 mb-lg-0"> {/* Margin for mobile */}
                    <div className='h-100'
                        style={{
                            ...imageStyle,
                           
                            padding:"10px",
                            backgroundRepeat:"no-repeat",
                            backgroundImage:`URL('${pastEventImg}')`,
                            backgroundSize:"cover",
                            
                            
                        }}
                        // src={upcomingEventImg}
                        // alt="Upcoming Event"
                >
                    <div className=" row mx-auto p-1 rounded"style={{
                        
                        opacity:"100%" 
                    }}   
                    >
                        <h2 className='text-center bg-white display-4 p-2' style={headingStyle}>Past Events</h2>
                         {
                            pastEvents && pastEvents.map((event,index)=>{
                                const banner= event.banner.split("/").pop();
                                
                                return(
                                    <div key={index} style={{opacity:"100%"}}className='col-sm-12 col-md-4 bg-white mx-auto rounded p-3 mt-2 d-flex flex-column'>
                                        <img src={`https://server.belestech.com/src/uploads/${banner}`} className='img-fluid rounded' style={{maxHeight:"300px"}} alt={event.title}/>
                                    <h3 className="mt-3 text-uppercase">{event.title}</h3>
                                    <p className="mt-2">
                                        {event.description}
                                    </p>
                                    <p className="mt-2">
                                      Location: {event.location}
                                    </p>
                                    <p className="mt-2 text-secondary small">
                                        Date: {moment(event.date).format('MMMM Do YYYY')}
                                    </p>
                                    <div className="mt-2">
                                        <button
                                            style={buttonStyle}
                                            onMouseOver={(e) => e.currentTarget.style.backgroundColor = buttonHoverStyle.backgroundColor}
                                            onMouseOut={(e) => e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor}
                                        >
                                            Learn More
                                        </button>
                                    </div>
                                    <hr/>
                                    </div>
                                  
                                )
                            })
                        } 
                       
                    </div>
                </div>
                    
                </div>}

                
            </div>

            <style jsx>{`
                @media (max-width: 576px) {
                    .container1 {
                        padding: 0 15px; /* Horizontal padding for mobile */
                    }
                    .row {
                        margin-bottom: 20px; /* Spacing between rows on mobile */
                    }
                }
            `}</style>
        </div>
    );
};

export default Event;
