import React, { useEffect,useRef,useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Row, Col } from 'react-bootstrap';
import './style/ResearchAndDevelopment.css'; // Custom CSS
import cactusImage from './img/logo.png'; 
import labImage from './img/lab.png';
import farmImage from './img/farm.jpg';
import researchBg from './img/research.jpg';
import productImage from './img/publications.jpg'; 
import cactusFabricImg from './img/fabric.jpg';
import bioenergyImg from './img/electro.png';
import farmingTechniquesImg from './img/ethio.png';
import collaborativeResearchImg from './img/farmer.jpeg';
import { FaDownload } from 'react-icons/fa';
import moment from 'moment';
import { getAllProjects, getAllPublications, getAllResearch, getAllResearchImages } from './api/api_functions';
import api from './api/api';

const ResearchAndDevelopment = () => {
  const [allresearch,setResearch]=useState(null);
  const researchref= useRef();
  const[allresearchImages,setImages]=useState(null);
  const [allpublications,setpublications]=useState({
    publications:[],
    presentations:[],
    caseStudies:[],
    technologyTransfer:[],
  });
  useEffect(()=>{
    researchref.current.scrollIntoView({behavior:"smooth"});
    getAllResearch().then((res)=>{
   
       setResearch(res.data);
    })
    getAllResearchImages().then((res)=>{
     
      setImages(res.data);
    })
    getAllPublications().then((res)=>{
         const publication= res.data.filter((data)=>data.status=="published");
        setpublications((pre)=>({
         ...pre,
          caseStudies:publication.filter((data)=>data.category=="case studies"),
          publications:publication.filter((data)=>data.category=="paper publication"),
          technologyTransfer:publication.filter((data)=>data.category=="technologyTransfer"),
          presentations:publication.filter((data)=>data.category=="conference presentations"),
        }))
    })
  },[])
  const researchPapers = [
    { title: 'Paper Title 1', description: 'Description for paper 1', link: '/path/to/paper1.pdf', publishedBy: 'Author 1' },
    { title: 'Paper Title 2', description: 'Description for paper 2', link: '/path/to/paper2.pdf', publishedBy: 'Author 2' },
  ];

  const conferencePresentations = [
    { title: 'Presentation Title 1', description: 'Description for presentation 1', link: '/path/to/presentation1', publishedBy: 'Presenter 1' },
    { title: 'Presentation Title 2', description: 'Description for presentation 2', link: '/path/to/presentation2', publishedBy: 'Presenter 2' },
  ];

  const caseStudies = [
    { title: 'Cactus-Based Food Products', description: 'Exploring the nutritional benefits and culinary uses of cactus.', link: '/case-study/food-products' },
    { title: 'Sustainable Textiles', description: 'Innovative applications of cactus in eco-friendly textile production.', link: '/case-study/textiles' },
  ];

  const technologyTransferExamples = [
    { title: 'Commercial Farm Collaborations', description: 'Showcasing partnerships with farms to implement sustainable practices.', link: '/technology-transfer/farm-collaboration' },
    { title: 'Small-Scale Enterprises', description: 'Supporting local businesses with research-based innovations.', link: '/technology-transfer/small-businesses' },
  ];
  const projects=[ 
    { img: cactusFabricImg, title: "Sustainable Cactus-Based Fabrics", text: "Detailed overview of the development of sustainable cactus-based fabrics, focusing on the environmental benefits and innovative processes involved." },
    { img: bioenergyImg, title: "Bioenergy Production", text: "Exploration of bioenergy production initiatives, highlighting successful implementations and the potential for renewable energy sources." },
    { img: farmingTechniquesImg, title: "Advanced Farming Techniques", text: "Overview of advanced farming techniques developed through research, showcasing improved crop yields and sustainable practices." },
    { img: collaborativeResearchImg, title: "Collaborative Research Initiatives", text: "Showcase of joint research initiatives with local and international institutions, highlighting successful outcomes and current progress." }
  ];
  const updateDownloadcount =async(id)=>{
    const download={download:true}
    const fullData = { data: { attributes: download } };
    const formData= new FormData();
    formData.append("data",JSON.stringify(fullData))
    try {
      const response=await api.patch(`/publication/${id}`,
       formData)
     
      
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div ref={researchref}>
      {/* Research Focus Areas */}
      <section className="py-2 d-flex align-items-center min-vh-100 position-relative" style={{ margin: '0', padding: '50px 15px' }}>
  <div className="container">
    <Row>
      <Col md={6} className="d-flex justify-content-center mb-4 mb-md-0">
        <img src={cactusImage} alt="Cactus" className="img-fluid rounded" style={{ maxHeight: '300px', width: 'auto', padding: '20px' }} />
      </Col>
      <Col md={6} className="shadow">
        <div className="bg-white p-4 shadow">
          <h1 className="display-5 fw-bold">Research Focus Areas</h1>
          <div className="my-3">
            <h3>Cactus Genetics</h3>
            <p>Highlighting research in genetic improvement to increase yield, disease resistance, and resilience in various climates.</p>
          </div>
          <div className="my-3">
            <h3>Sustainable Farming Techniques</h3>
            <p>Showcasing innovations in cactus farming that improve resource efficiency, such as water conservation and soil management.</p>
          </div>
          <div className="my-3">
            <h3>Pest and Disease Management</h3>
            <p>Research efforts aimed at creating eco-friendly, effective solutions to manage pests and diseases that impact cactus farming.</p>
          </div>
          <div className="my-3">
            <h3>Product Innovation</h3>
            <p>Detailing the development of new applications for cactus in areas such as food production, textile manufacturing (vegan leather), energy production, and more.</p>
          </div>
          <a className="btn btn-lg btn-primary" href="/contact">Learn More</a>
        </div>
      </Col>
    </Row>
  </div>
</section>

      {/* Research Facilities Section */}
      <section className="py-5" style={{ backgroundImage: `url(${researchBg})`, backgroundSize: 'cover', backgroundPosition: 'center', color: 'white', margin: '0' }}>
        <div className="full-width-container">
          <Row className="align-items-center g-0">
            <Col md={6} className="text-center text-md-start mb-4 mb-md-0" style={{ padding: '20px' }}>
              <h1 className="display-4 cta fw-bold">Advancing Agricultural Research through Innovation</h1>
              <p className="cta">Discover our cutting-edge research facilities that drive sustainable solutions in agriculture.</p>
            </Col>
            <Col md={6} className="text-center text-md-end">
              <a className="btn btn-light btn-lg fw-semibold cta-button" href="/project">Explore Our Research Facilities</a>
            </Col>
          </Row>
        </div>
      </section>

      {/* Key Projects Section */}
      <section className="py-5" style={{ margin: '0' }}>
  <div className="container1" style={{ padding: '20px' }}>
    <Row className="justify-content-center text-center mb-3 mt-5 g-0">
      <Col lg={8} xl={7}>
      
        <h2 className="display-5 fw-bold">Our Key Research</h2>
        <p className="lead">Explore our major research focused on sustainability and innovation in agriculture.</p>
      </Col>
    </Row>
    <Row className="g-4 g-md-5">
      {allresearch && allresearch.map((research, index) => {
        const [image]= allresearchImages.filter((img)=>(img.researchId==research.researchId && img.isThumbnail==1))
        const image_url=image && image.file.split("/").pop();
        
        return(
        <Col xs={12} md={6} key={index} className="mb-3">
          <div className="card mb-3 shadow project-card" style={{ maxWidth: '540px', backgroundColor: 'white', padding: '15px' }}>
            <div className="row g-0">
              <div className="col-md-4">
                <img 
                  src={`https://server.belestech.com/src/uploads/${image_url}`}
                  className="img-fluid rounded " 
                  alt={research.title} 
                
                />
              </div>
              <div className="col-md-8">
                <div className="card-body ">
                  <h6 className="card-title text-justify " style={{fontSize:"17px",fontWeight:500}}>{research.title}</h6>
                  <p className="card-text text-small text-muted text-justify">{research.description}</p>
                  <p className="card-text small text-muted text-end text-justify" style={{fontSize:"14px",fontWeight:500}}>{research.status}</p>
                </div>
              </div>
            </div>
          </div>
        </Col>
      )})}
    </Row>
  </div>
</section>

<style jsx>{`
  .project-card {
    transition: transform 0.2s, box-shadow 0.2s;
  }

  .project-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 576px) {
    .card-title {
      font-size: 1.25rem; /* Smaller font size for mobile */
    }
    .card-text {
      font-size: 0.9rem; /* Smaller font size for mobile */
    }
    .card-body {
      padding: 10px; /* Padding for better spacing */
    }
  }

  @media (min-width: 576px) {
    .card-title {
      font-size: 1.5rem; /* Medium font size for larger devices */
    }
    .card-text {
      font-size: 1rem; /* Medium font size for larger devices */
    }
  }

  @media (min-width: 992px) {
    .card-title {
      font-size: 1.75rem; /* Larger font size for desktops */
    }
    .card-text {
      font-size: 1.1rem; /* Larger font size for desktops */
    }
  }
`}</style>

{/* Research Facilities */}
<h1 className="text-center display-5 fw-bold mb-4">Our Research Facilities</h1>
<p className="text-center mb-5">Our state-of-the-art research infrastructure supports innovative agricultural research.</p>
<Row className="g-0">
  <Col xs={12} sm={5} className="mb-4 mx-auto ">
    <div className="facility-card p-3 h-100">
      <img 
        src={labImage} 
        alt="Research Laboratory, Adigrat University" 
        className="img-fluid custom-shape facility-image" 
        style={{ objectFit: 'contain' }} 
      />
    <h6 className="text-secondary small text-center"> Research Laboratory, Adigrat University</h6>
      <h3 className="mt-3">Laboratories</h3>
      <p>State-of-the-art laboratories equipped for cutting-edge research and experimentation in agricultural sciences.</p>
    </div>
  </Col>
  <Col xs={12} sm={5} className="mb-4  mx-auto ">
    <div className="facility-card p-3 h-100">
      <img 
        src={farmImage} 
        alt="Plantations at research field, Adigrat University" 
        className="img-fluid custom-shape facility-image" 
        style={{ objectFit: 'contain' }} 
      />
      <h6 className="text-secondary small text-center"> Plantations at research field, Adigrat University</h6>
      <h3 className="mt-3">Pilot Farms</h3>
      <p>Experimental farms where new farming methods are tested and demonstrated, contributing to sustainable agriculture solutions.</p>
    </div>
  </Col>
</Row>

{/* Publications and Case Studies */}
<div className="my-5">
  <h2 className="text-center display-5 fw-bold mb-4">Publications</h2>
  <Row className="mb-5 g-3">
    {
      allpublications.publications.length!==0?(<>
     
      {
        allpublications.publications.map((paper,index)=>{
          let link= paper.file && paper.file.split("/").pop();
          link='https://server.belestech.com/uploads/'+link;
         
           return(
          <Col xs={12} md={6} lg={3} key={index} className='mx-auto p-3'>
          <div className="border rounded p-3 shadow-sm" style={{ padding: '15px', margin: '0 10px' }}>
            <div className="text-end">
              <a href={link} target='_blank' onClick={()=>updateDownloadcount(paper.publicationId)} download className="text-decoration-none">
                <FaDownload size={50} />
              </a>
            </div>
            <h5>{paper.title}</h5>
            <p>{paper.description}</p>
            <p className="text-muted">Published by: {paper.author}</p>
            <p className="text-muted">Published date: {moment(paper.date).format("DD-MM-YYYY")}</p>
          </div>
        </Col>)
        })
      }

      </>)
      :
      <>
         {researchPapers.map((paper, index) => (
          <Col xs={12} md={6} lg={3} key={index} className='mx-auto p-3'>
            <div className="border rounded p-3 shadow-sm" style={{ padding: '15px', margin: '0 10px' }}>
              <div className="text-end">
                <a href={paper.link}  className="text-decoration-none">
                  <FaDownload size={50} />
                </a>
              </div>
              <h5>{paper.title}</h5>
              <p>{paper.description}</p>
              <p className="text-muted">Published by: {paper.publishedBy}</p>
            </div>
          </Col>
        ))}
      </>
     
 
    }
    
  </Row>

  <Row>
    <Col xs={12}>
      <h3 className='text-center display-5 fw-bold'>Conference Presentations</h3>
      <Row className="g-3">
        { allpublications.presentations.length!==0?(<>
          {allpublications.presentations.map((presentation, index) =>{
          let link= presentation.file && presentation.file.split("/").pop();
          link='https://server.belestech.com/uploads/'+link;
          return(
          <Col xs={12} md={6} lg={3} key={index} className='mx-auto p-3'>
            <div className="border rounded p-3 shadow-sm" style={{ padding: '15px', margin: '0 10px' }}>
              <div className="text-end">
                <a href={link} target='_blank' onClick={()=>updateDownloadcount(presentation.publicationId)} download className="text-decoration-none">
                  <FaDownload size={50} />
                </a>
              </div>
              <h5>{presentation.title}</h5>
              <p>{presentation.description}</p>
              <p className="text-muted small">Published by: {presentation.publishedBy}</p>
            </div>
          </Col>
        )})}

        </>):(<>
          {conferencePresentations.map((presentation, index) => (
          <Col xs={12} md={6} lg={3} key={index} className='mx-auto p-3'>
            <div className="border rounded p-3 shadow-sm" style={{ padding: '15px', margin: '0 10px' }}>
              <div className="text-end">
                <a href={presentation.link} className="text-decoration-none">
                  <FaDownload size={50} />
                </a>
              </div>
              <h5>{presentation.title}</h5>
              <p>{presentation.description}</p>
              <p className="text-muted">Published by: {presentation.publishedBy}</p>
            </div>
          </Col>
        ))}
        </>)}
 
      </Row>
    </Col>
  </Row>

  {/* Case Studies Section */}
  <section className="py-5" style={{ margin: '0' }}>
    <div className="full-width-container">
      <Row className="mb-4 g-0">
        <Col>
          <h2 className="fw-bold text-center">Case Studies</h2>
          <p className='text-center'>In-depth features on successful innovations demonstrating the impact of research.</p>
        </Col>
      </Row>
     {  allpublications.caseStudies.length!==0?(
      <>
          <Row className="g-4">
        {
        allpublications.caseStudies.map((paper,index)=>{
          let link= paper.file && paper.file.split("/").pop();
                link='https://server.belestech.com/uploads/'+link;
         
           return(
          <Col xs={12} md={6} lg={3} key={index} className='mx-auto p-3'>
          <div className="border rounded p-3 shadow-sm" style={{ padding: '15px', margin: '0 10px' }}>
            <div className="text-end">
              <a href={link} onClick={()=>updateDownloadcount(paper.publicationId)}  target='_blank'download className="text-decoration-none">
                <FaDownload size={50} />
              </a>
            </div>
            <h5>{paper.title}</h5>
            <p>{paper.description}</p>
            <p className="text-muted">Published by: {paper.author}</p>
            <p className="text-muted small">Published date: {moment(paper.date).format("DD-MM-YYYY")}</p>
          </div>
        </Col>)
        })
      }
  </Row>
     </>):
     (
      <Row className="g-4">
        {caseStudies.map((study, index) => (
          <Col md={6} key={index}>
            <div className="border rounded p-3 shadow-sm" style={{ padding: '15px', margin: '0 10px' }}>
              <div className="text-end">
                <a href={study.downloadLink} className="text-decoration-none">
                  <FaDownload size={50} />
                </a>
              </div>
              <h5>{study.title}</h5>
              <p>{study.description}</p>
              <p className="text-muted">Published by: {study.publishedBy}</p>
             
            </div>
          </Col>
        ))}
       
      </Row>
     )
      }
    </div>
  </section>
</div>

<style jsx>{`
  .facility-image {
    max-height: 300px; /* Default size for web */
  }

  @media (max-width: 576px) {
    .facility-image {
      max-height: 150px; /* Smaller size for mobile */
      margin: 0 auto; /* Center the image */
    }
    .facility-card {
      padding: 15px; /* Additional padding for cards */
    }
  }
`}</style>

     
  {/* Final Publications Section */}
  <section className="py-5 text-center" style={{ margin: '0' }}>
        <h2 className="fw-bold">Publications</h2>
        <p>Explore our published research articles and findings.</p>
        <img src={productImage} alt="Innovation Products" className="img-fluid rounded w-100" style={{ maxHeight: '300px', objectFit: 'cover' }} />
      </section>

      {/* Technology Transfer Section */}
      <section className="py-5" style={{ backgroundColor: '#f8f9fa', margin: '0' }}>
        <div className="full-width-container">
          <Row className="mb-4 g-0">
            <Col>
              <h2 className="fw-bold text-center">Technology Transfer</h2>
              <p className='text-center'>Explaining how research findings are applied and transferred to commercial farms, industries, and small-scale enterprises.</p>
            </Col>
          </Row>
          <Row className="g-4">
              {
                 allpublications.technologyTransfer.length!==0?(<>
                  {allpublications.technologyTransfer.map((example, index) => {
                   let link= example.file && example.file.split("/").pop();
                   link='https://server.belestech.com/uploads/'+link;
                  return(
              <Col md={3} key={index} className='mx-auto'>
                <div className="border rounded p-3 shadow-sm">
                  <h5>{example.title}</h5>
                  <p>{example.description}</p>
                  <a href={example.link} onClick={()=>updateDownloadcount(example.publicationId)} className="btn btn-primary">Learn More</a>
                </div>
              </Col>
            )})}
                    
                 </>):(<>
                  {
                  technologyTransferExamples.map((example, index) => (
              <Col md={3} key={index} className='mx-auto'>
                <div className="border rounded p-3 shadow-sm">
                  <h5>{example.title}</h5>
                  <p>{example.description}</p>
                  <a href={example.link} className="btn btn-primary">Learn More</a>
                </div>
              </Col>
            ))}

                 </>)

              }


        
          </Row>
        </div>
      </section>

    
    </div>
  );
};

export default ResearchAndDevelopment;